import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from '../contexts/AuthContext';
import { StoreContext } from '../contexts/StoreContext';

const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

function Purchases() {
  const { token, isAuthenticated } = useContext(AuthContext);
  //const [cellContents, setCellContents] = useState({}); // What is this?
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('processing');
  const [filterTitle, setFilterTitle] = useState('Processing');
  const [shippingDetails, setShippingDetails] = useState({});
  const { stores, selectedStore, setSelectedStore, setStoreLogo, fetchStores } = useContext(StoreContext);

  useEffect(() => {
    if (isAuthenticated && token) {
      console.log('Token:', token); // Debugging statement
      fetchStores();
    } else {
      console.log('Token is not available'); // Debugging statement
    }
  }, [token, filter]);

  // Fetch products for the selected store
  useEffect(() => {
    if (selectedStore) {
      handleFetchPurchases(selectedStore);
    }
  }, [selectedStore]);

  // Function to update the content of a specific cell
  /*
  const handleCellContentUpdate = (cellId, newContent) => {
    setCellContents(prevContents => ({
      ...prevContents,
      [cellId]: newContent
    }));
  };
*/

  const buildOrderSummary = (items, seperator) => {
    if (items && items.length > 0) {
      return items.map(item => {
        const {
          description,
          quantity
        } = item;

        return `${description} x ${quantity}`;
      }).join(seperator);
    } else {
      return;
    }
  };

  // Fetch purchases logic
  const handleFetchPurchases = async (storeId) => {
    setLoading(true);
    setError(null);
    console.log('Fetch Purchases for store id: ' + storeId);

    if (token) {
      try {

        // Fetch user settings for shipping dimensions
        const settingsResponse = await fetch(`${STORE_BACKEND_URL}/settings`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!settingsResponse.ok) {
          throw new Error('Error fetching user settings.');
        }
        const userSettings = await settingsResponse.json();

        // Fetch purchases
        const response = await fetch(`${STORE_BACKEND_URL}/purchases/store/${storeId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Error fetching purchases. Please check your authentication.');
        }
        const purchases = await response.json();
        setPurchases(purchases);

        // Initialize shipping details with default values for each purchase
        const initialShippingDetails = {};
        purchases.forEach(purchase => {
          initialShippingDetails[purchase._id] = {
            height: userSettings.shipping ? userSettings.shipping.height : '2',
            width: userSettings.shipping ? userSettings.shipping.width : '5',
            length: userSettings.shipping ? userSettings.shipping.length : '5',
            weight: userSettings.shipping ? userSettings.shipping.weight : '100'
          };
        });
        setShippingDetails(initialShippingDetails);

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleShippingLabelOrderClick = async (purchase, vendor) => {
    const userConfirmed = window.confirm('This will place an order for the shipping label and your CC will be charged. Good?');
    if (userConfirmed) {

      const shippingDetailsForPurchase = shippingDetails[purchase._id] || {
        height: '2',
        width: '5',
        length: '5',
        weight: '100'
      };

      const customFieldsString = purchase.custom_fields && purchase.custom_fields
        .filter(field => field.text.value)
        .map(field => `${field.label.custom}: ${field.text.value}`)
        .join(', ');

      console.log('Order Shipping Label. Custom Fields: ' + customFieldsString);

      if (vendor === 'sendle') {
        try {
          const response = await fetch(`${STORE_BACKEND_URL}/shipping/sendle/order`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              purchase: purchase,
              description: customFieldsString,
              ...shippingDetailsForPurchase
            })
          });

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(JSON.stringify(errorData.messages) || 'Error ordering the label.');
          } else {
            toast.success("Shipment created on Sendle!");
            handleFetchPurchases();
            setLoading(false);
          }

        } catch (error) {
          setError(error.message);
        }
      } else if (vendor === 'chitchats') {
        try {
          const response = await fetch(`${STORE_BACKEND_URL}/shipping/chitchats/order`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
              purchase: purchase,
              description: customFieldsString,
              ...shippingDetailsForPurchase
            })
          });

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(JSON.stringify(errorData.messages) || 'Error creating shipment');
          } else {
            toast.success("Shipment created on ChitChats!");
            handleFetchPurchases();
            setLoading(false);
          }

        } catch (error) {
          setError(error.message);
        }
      }
    }
  };

  const handleShippingInputChange = (e, purchaseId) => {
    const { name, value } = e.target;
    setShippingDetails(prevState => ({
      ...prevState,
      [purchaseId]: {
        ...prevState[purchaseId],
        [name]: value
      }
    }));
  };

  const handleShippingQuotesSubmit = async (e, purchase) => {
    toast.success(`Fetching shipping quotes`);
    e.preventDefault();

    const shippingDetailsForPurchase = shippingDetails[purchase._id] || {
      height: '2',
      width: '5',
      length: '5',
      weight: '100'
    };

    const customFieldsString = purchase.custom_fields && purchase.custom_fields
      .filter(field => field.text.value)
      .map(field => `${field.label.custom}: ${field.text.value}`)
      .join(', ');

    console.log('Custom Fields: ' + customFieldsString);

    const payload = {
      purchase: purchase,
      description: customFieldsString,
      ...shippingDetailsForPurchase
    };

    let sendleQuote = null;
    let chitchatsQuotes = [];

    try {
      const sendleResponse = await fetch(`${STORE_BACKEND_URL}/shipping/sendle/quote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });

      if (!sendleResponse.ok) {
        const errorData = await sendleResponse.json();
        throw new Error(JSON.stringify(errorData.messages) || 'Error submitting the Sendle shipping form');
      } else {
        const sendleData = await sendleResponse.json();
        const sendleResult = sendleData.find(item => item.product.code === "STANDARD-PICKUP");
        const sendleGrossAmount = sendleResult.quote.gross.amount;
        const sendleCurrency = sendleResult.quote.net.currency;

        sendleQuote = {
          amount: sendleGrossAmount,
          currency: sendleCurrency,
          service: 'Sendle'
        };
      }
    } catch (error) {
      toast.error(`Sendle: ${error.message}`);
    }

    try {
      const chitchatsResponse = await fetch(`${STORE_BACKEND_URL}/shipping/chitchats/quote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload)
      });

      if (!chitchatsResponse.ok) {
        const errorData = await chitchatsResponse.json();
        throw new Error(JSON.stringify(errorData.messages) || 'Error submitting the ChitChats shipping form');
      } else {
        const chitchatsData = await chitchatsResponse.json();
        const excludedPostageTypes = ['chit_chats_collect', 'usps_priority'];

        chitchatsQuotes = chitchatsData.shipment.rates
          .filter(rate => !excludedPostageTypes.includes(rate.postage_type))
          .map(rate => ({
            amount: rate.payment_amount,
            currency: 'CAD',
            service: rate.postage_description
          }));

      }
    } catch (error) {
      toast.error(`ChitChats: ${error.message}`);
    }

    handleCellContentUpdate(purchase._id,
      <div>
        {sendleQuote && (
          <button className='sunthar-button' style={{ background: '#90ee90', width: '200px', }} onClick={() => handleShippingLabelOrderClick(purchase, 'sendle')}>
            ${sendleQuote.amount} {sendleQuote.currency} <br />
            {sendleQuote.service}
          </button>
        )}
        {chitchatsQuotes.length > 0 && chitchatsQuotes.map((quote, index) => (
          <button key={index} style={{ background: '#90ee90', width: '200px', marginTop: '5px' }} onClick={() => handleShippingLabelOrderClick(purchase, 'chitchats')}>
            ${quote.amount} {quote.currency} <br />
            {quote.service}
          </button>
        ))}
      </div>
    );

    setLoading(false);
  };

  const handleShippingCheckStatus = async (purchase) => {
    try {
      const { shipping_info } = purchase;

      console.log ('Checking email status: ' + JSON.stringify(shipping_info));

      if (shipping_info.sendle) {
        try {
          const response = await fetch(`${STORE_BACKEND_URL}/shipping/sendle/status`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
              purchase: purchase
            })
          });

          const sendleResponse = await response.json();

          if (!response.ok) {
            throw new Error('Error fetching shipping status. Please check your request.');
          } else {
            setLoading(false);
            toast.success("Shipping status updated to " + sendleResponse.state);
            if (sendleResponse.email_status && sendleResponse.email_status.shipment_email) {
              toast.success("Email sent or already sent!");
              handleFetchPurchases();
            } else {
              console.error('Email not sent ' + JSON.stringify(sendleResponse));
              toast.error("Email not sent!");
            }
          }
        } catch (error) {
          setLoading(false);
          setError(error.message);
        }

      } else if (shipping_info.chitchats) {
        try {
          const response = await fetch(`${STORE_BACKEND_URL}/shipping/chitchats/status`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
              purchase: purchase
            })
          });

          const chitchatsResponse = await response.json();

          if (!response.ok) {
            throw new Error('Error fetching shipping status. Please check your request.');
          } else {
            setLoading(false);
            toast.success("Shipping status updated to " + chitchatsResponse.shipment.status);
            if (chitchatsResponse.email_status && chitchatsResponse.email_status.shipment_email) {
              toast.success("Email sent or already sent!");
              handleFetchPurchases();
            } else {
              console.error('Email not sent ' + JSON.stringify(chitchatsResponse));
              toast.error("Email not sent!");
            }
          }
        } catch (error) {
          setLoading(false);
          setError(error.message);
        }

      } else {
        console.log('No known shipping vendor found in shipping_info.');
      }

    } catch (error) {
      toast.error("Error updating shipping status: " + error);
      console.error('Error updating shipping status:', error);
    }
  };

  const handleShippingStatus = async (purchase, status) => {
    try {
      const response = await fetch(`${STORE_BACKEND_URL}/purchases/update-shipping-status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          purchase: purchase,
          status: status
        }),
      });

      if (response.ok) {
        toast.success("Shipping status updated to " + status);
        handleFetchPurchases();
      } else {
        const errorData = await response.json();
        toast.error("Error updating shipping status: " + JSON.stringify(errorData));
        console.error('Error updating shipping status:', errorData);
      }
    } catch (error) {
      toast.error("Error updating shipping status: " + error);
      console.error('Error updating shipping status:', error);
    }
  };

  const filteredPurchases = purchases.filter((purchase) => {
    if (purchase.status !== 'succeeded') {
      return false;
    }

    if (purchase.type === 'invoice') {
      return false;
    }

    switch (filter) {
      case 'processing':
        return !purchase.shipping_status || purchase.shipping_status === 'processing';
      case 'labelCreated':
        return purchase.shipping_status === 'label_created';
      case 'inTransit':
        return purchase.shipping_status === 'in_transit';
      case 'delivered':
        return purchase.shipping_status === 'delivered';
      case 'suspended':
        return purchase.shipping_status === 'suspended';
      case 'returned':
        return purchase.shipping_status === 'returned';
      case 'canceled':
        return purchase.shipping_status === 'canceled';
      case 'failed':
        return purchase.shipping_status === 'failed';
      default:
        return true;
    }
  });

  const handleSelect = (filter, title) => {
    setFilter(filter);
    setFilterTitle(title);
  };

  const handleStoreChange = (e) => {
    const newStoreId = e.target.value;
    setSelectedStore(newStoreId);
    localStorage.setItem('selectedStore', newStoreId); // Save selected store to localStorage

    const selected = stores.find(store => store._id === newStoreId);
    setStoreLogo(selected ? selected.store_logo : '');
  };

  const getShippingPartner = (purchase) => {
    if (purchase.shipping_info) {
      if (purchase.shipping_info.chitchats) {
        return 'chitchats';
      }
      if (purchase.shipping_info.sendle) {
        return 'sendle';
      }
    }
    return 'LABEL NOT CREATED';
  };

  const TrackingLink = ({ purchase }) => {
    const getTrackingUrl = () => {
      if (purchase.shipping_info) {
        if (purchase.shipping_info.chitchats && purchase.shipping_info.chitchats.shipment) {
          return purchase.shipping_info.chitchats.shipment.tracking_url;
        }
        if (purchase.shipping_info.sendle) {
          return purchase.shipping_info.sendle.tracking_url;
        }
      }
      return null;
    };

    const url = getTrackingUrl();

    return (
      <a href={url || '#'} target='_blank' rel="noopener noreferrer">
        {purchase.shipping_id}
      </a>
    );
  };

  return (
    <div className="theme-container">
      <ToastContainer />
      <div className="headline d-flex justify-content-between align-items-center">
        <h2 className="underline">Purchases</h2>
        <div className="form-group">
          <label></label>
          <select
            id="storeSelect"
            className="form-control"
            value={selectedStore}
            onChange={handleStoreChange} // Use the handleStoreChange function
          >
            {stores.map((store) => (
              <option key={store._id} value={store._id}>
                {store.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <Link to={`/add-purchase/${selectedStore}`}>
          <h6>Add Purchase</h6>
        </Link>
      </div>
      <div className="filter-buttons">
        <DropdownButton id="dropdown-basic-button" title={`${filterTitle}`} align="end">
          <Dropdown.Item onClick={() => handleSelect('all', 'All')}>All</Dropdown.Item>
          <Dropdown.Item onClick={() => handleSelect('processing', 'Processing')}>Processing</Dropdown.Item>
          <Dropdown.Item onClick={() => handleSelect('labelCreated', 'Label Created')}>Label Created</Dropdown.Item>
          <Dropdown.Item onClick={() => handleSelect('inTransit', 'In Transit')}>In Transit</Dropdown.Item>
          <Dropdown.Item onClick={() => handleSelect('delivered', 'Delivered')}>Delivered</Dropdown.Item>
          <Dropdown.Item onClick={() => handleSelect('suspended', 'Suspended')}>Suspended (Review)</Dropdown.Item>
          <Dropdown.Item onClick={() => handleSelect('canceled', 'Canceled')}>Canceled</Dropdown.Item>
        </DropdownButton>
      </div>
      {loading && <div>Loading...</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <h4>Total records: {filteredPurchases.length}</h4>
      {!loading && !error && filteredPurchases.length > 0 && (
        <div className="row">
          {filteredPurchases.map((purchase) => (
            <div className="col-md-12" key={purchase._id}>
              <div className="card mb-12" style={{ marginBottom: '10px' }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6" style={{ textAlign: 'left' }}>
                      <h6 className="card-title">
                        <strong>{purchase.name}</strong> <Link to={`/edit-purchase/${purchase._id}`}>Edit</Link>
                        <br />
                        {purchase.email}
                      </h6>
                      {new Date(purchase.created_at).toLocaleString()}
                      <br />
                      {purchase.custom_fields && (
                        <div>
                          {purchase.custom_fields.map((field, index) => (
                            <span key={index}>
                              <strong>{field.label.custom}:</strong> {field.text && field.text.value ? field.text.value.toUpperCase() : ''}
                            </span>
                          ))}
                        </div>
                      )}
                      <pre>{buildOrderSummary(purchase.items, '\n')}</pre>
                      <p>
                        ${(purchase.amount / 100).toFixed(2)} {purchase.currency && purchase.currency.toUpperCase()}
                      </p>
                      <p>
                        {purchase.shipping && purchase.shipping.address && (
                          <>
                            {purchase.shipping.address.line1}<br />
                            {purchase.shipping.address.line2 && <>{purchase.shipping.address.line2}<br /></>}
                            {purchase.shipping.address.city}<br />
                            {(purchase.shipping?.address?.state || purchase.shipping?.address?.country || purchase.shipping?.address?.postal_code) && (
                              <>
                                {purchase.shipping?.address?.state && `${purchase.shipping.address.state}`}
                                {purchase.shipping?.address?.state && purchase.shipping?.address?.country && ', '}
                                {purchase.shipping?.address?.country && `${purchase.shipping.address.country}`}
                                {purchase.shipping?.address?.postal_code && ` (${purchase.shipping.address.postal_code})`}
                                <br />
                              </>
                            )}
                          </>
                        )}
                      </p>
                      <p>
                        <strong>{getShippingPartner(purchase).toUpperCase()}</strong>
                        <br />
                        <strong>{purchase.shipping_status && purchase.shipping_status.toUpperCase()}</strong>
                        <br />
                        <TrackingLink purchase={purchase} />
                      </p>
                    </div>
                    <div className="col-md-6" style={{ textAlign: 'right' }}>
                      <div className="card-text">
                        {purchase.shipping_status === 'label_created' ? (
                          purchase.shipping && purchase.shipping.address ? (
                            <div>
                              <button onClick={() => handleShippingStatus(purchase, 'processing')}>
                                Move back to Processing
                              </button>
                              <button onClick={() => handleShippingCheckStatus(purchase)}>
                                Check Status
                              </button>
                            </div>
                          ) : null
                        ) : (purchase.shipping_status === 'in_transit') ? (
                          purchase.shipping && purchase.shipping.address ? (
                            <div>
                              <button onClick={() => handleShippingStatus(purchase, 'label_created')}>
                                Move back to Label Created
                              </button>
                              <button onClick={() => handleShippingCheckStatus(purchase)}>
                                Check Status
                              </button>
                            </div>
                          ) : null
                        ) : (purchase.shipping && (purchase.shipping_status == null || purchase.shipping_status === 'processing')) ? (
                          <div>
                            {/*
                            <div>
                              {cellContents[purchase._id] || ''}
                            </div>
                            */}
                            <div className="d-flex justify-content-end">
                              <div style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>
                                W x H x L (in):
                                <div className="input-group" style={{  marginLeft: '10px', maxWidth: '300px' }}>
                                  <input
                                    type="text"
                                    name="height"
                                    className="form-control"
                                    plceholder="height (in)"
                                    style={{ textAlign: 'right' }}
                                    value={shippingDetails[purchase._id]?.height || '2'}
                                    onChange={(e) => handleShippingInputChange(e, purchase._id)}
                                  />
                                  <input
                                    type="text"
                                    name="width"
                                    plceholder="width (in)"
                                    className="form-control"
                                    style={{ textAlign: 'right' }}
                                    value={shippingDetails[purchase._id]?.width || '5'}
                                    onChange={(e) => handleShippingInputChange(e, purchase._id)}
                                  />
                                  <input
                                    type="text"
                                    name="length"
                                    plceholder="length (in)"
                                    className="form-control"
                                    style={{ textAlign: 'right' }}
                                    value={shippingDetails[purchase._id]?.length || '5'}
                                    onChange={(e) => handleShippingInputChange(e, purchase._id)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end">
                              <div style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>
                                Weight (lbs):
                                <input
                                  type="text"
                                  name="weight"
                                  className="form-control"
                                  style={{ width: '100px', marginLeft: '10px', textAlign: 'right' }}
                                  value={shippingDetails[purchase._id]?.weight || '100'}
                                  onChange={(e) => handleShippingInputChange(e, purchase._id)}
                                />
                              </div>
                            </div>
                            <button onClick={() => handleShippingStatus(purchase, 'label_created')}>
                              Move to Label Created
                            </button>
                            <button onClick={(e) => handleShippingQuotesSubmit(e, purchase)} className='sunthar-button'>
                              Get Shipping Quotes
                            </button>
                          </div>
                        ) : (purchase.shipping_status === 'in_transit') ? (
                          <p>
                            Shipment Email: {(purchase.email_status && purchase.email_status.shipment_email ? 'Sent' : 'Not Sent')}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Purchases;
