import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from '../contexts/AuthContext';
import { Form } from 'react-bootstrap';

const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

function ProductWaitlist() {
  const { product_id } = useParams();
  const location = useLocation(); // Use useLocation to get state
  const { token, isAuthenticated } = useContext(AuthContext);
  const [waitlistEmails, setWaitlistEmails] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log('Product Name from state:', JSON.stringify(location));

  // Retrieve the product name from location state
  const productName = location.state?.productName || 'Product';

  useEffect(() => {
    if (isAuthenticated && token) {
      handleFetchWaitlistEmails();
    } else {
      console.log('Token is not available');
    }
  }, [token]);

  const handleFetchWaitlistEmails = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${STORE_BACKEND_URL}/waitlist/products/${product_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error fetching waitlist emails.');
      }

      const emailData = await response.json();
      setWaitlistEmails(emailData);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleNotify = async (email, currentNotifyStatus) => {
    try {
      const response = await fetch(`${STORE_BACKEND_URL}/waitlist/products/${product_id}/toggle-notification`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          product_id: product_id,
          notify: !currentNotifyStatus // Toggle the notify status
        }),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.message || 'Error updating notification status.');
      }
  
      //toast.success(data.message || 'Notification status updated successfully!');
      handleFetchWaitlistEmails(); // Refresh the waitlist after updating
    } catch (error) {
      //toast.error(error.message || 'An unexpected error occurred while updating notification status.');
    }
  };

  return (
    <div className="theme-container">
      <ToastContainer />
      <h2>Waitlist for: {productName}</h2>
      {loading && <div>Loading...</div>}
      {!loading && waitlistEmails.length > 0 ? (
        <div className="row">
          {waitlistEmails.map((emailObj, idx) => {

            // Extract the latest charge ID
            const latestChargeLink = emailObj.latest_purchase
                ? `/edit-purchase/${emailObj.latest_purchase.latest_charge}`
                : null;

            return (
              <div className="col-md-6" key={idx}>
                <div className="card mb-6" style={{ marginBottom: '10px' }}>
                  <div className="card-body">
                    <h5 className="card-title">{emailObj.email}</h5>
                    <div className="card-text">
                      <strong>Email Status:</strong> {emailObj.notification_email} <br />
                      <strong>Last Purchase:</strong>{' '}
                      {latestChargeLink ? (
                        <Link to={latestChargeLink}>{emailObj.latest_purchase.latest_charge}</Link>
                      ) : 'No Purchases'}
                    </div>
                    <div className="card-text d-flex align-items-center">
                      <strong>Notify:</strong>
                      <Form.Check
                        type="checkbox"
                        checked={emailObj.notify} // Check based on notify status
                        onChange={() => handleToggleNotify(emailObj.email, emailObj.notify)}
                        className="mx-2" // Add margin for spacing
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p>No users in the waitlist for this product.</p>
      )}
    </div>
  );
}

export default ProductWaitlist;
