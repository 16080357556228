// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-name {
  white-space: nowrap;         /* Prevents the name from wrapping */
  overflow: hidden;            /* Hides any overflowed content */
  text-overflow: ellipsis;     /* Displays the ellipsis when overflowed */
}

.product-description {
  display: -webkit-box;        /* Use WebKit's box model for multiline clamping */
  -webkit-line-clamp: 4;       /* Limit the description to 4 lines */
  -webkit-box-orient: vertical;/* Specifies vertical box orientation */
  overflow: hidden;            /* Hides overflowing content */
  text-overflow: ellipsis;     /* Displays an ellipsis when content overflows */
}`, "",{"version":3,"sources":["webpack://./src/pages/Products.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB,UAAU,oCAAoC;EACjE,gBAAgB,aAAa,iCAAiC;EAC9D,uBAAuB,MAAM,0CAA0C;AACzE;;AAEA;EACE,oBAAoB,SAAS,kDAAkD;EAC/E,qBAAqB,QAAQ,qCAAqC;EAClE,4BAA4B,CAAC,uCAAuC;EACpE,gBAAgB,aAAa,8BAA8B;EAC3D,uBAAuB,MAAM,gDAAgD;AAC/E","sourcesContent":[".product-name {\n  white-space: nowrap;         /* Prevents the name from wrapping */\n  overflow: hidden;            /* Hides any overflowed content */\n  text-overflow: ellipsis;     /* Displays the ellipsis when overflowed */\n}\n\n.product-description {\n  display: -webkit-box;        /* Use WebKit's box model for multiline clamping */\n  -webkit-line-clamp: 4;       /* Limit the description to 4 lines */\n  -webkit-box-orient: vertical;/* Specifies vertical box orientation */\n  overflow: hidden;            /* Hides overflowing content */\n  text-overflow: ellipsis;     /* Displays an ellipsis when content overflows */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
