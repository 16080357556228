import React, { useContext, useRef, useEffect,useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FaHome, FaBoxOpen, FaShoppingCart, FaShippingFast, FaCogs, FaMoon, FaSun } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import './NavigationBar.css'; // Add any specific styles if needed
import { AuthContext } from '../contexts/AuthContext';
import { StoreContext } from '../contexts/StoreContext';

// With React you have to prefix the environment variable with REACT_APP_
const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

const NavigationBar = ({ toggleTheme, isDarkTheme }) => {
  const { isAuthenticated, setIsAuthenticated, setToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null);
  const { storeLogo } = useContext(StoreContext);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      closeNavbar();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${STORE_BACKEND_URL}/auth/logout`, {
        method: 'GET', // Assuming your logout route is a GET request
        credentials: 'include', // Send cookies to the backend to clear them
      });

      if (response.ok) {
        localStorage.removeItem('token'); // Clear token from localStorage
        setIsAuthenticated(false); // Update the authentication context
        setToken(null); // Clear token from state
        navigate('/'); // Redirect to the home or login page
      } else {
        console.error('Logout failed.');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <Navbar ref={navbarRef} expand="lg" expanded={isOpen}>
        <Navbar.Brand as={Link} to="/">
          {storeLogo ? (
            <img className="nav-logo" src={storeLogo} alt="Store Logo" style={{ height: '2.8rem' }} />
          ) : (
            <img className="nav-logo" src="/logo.png" alt="Sunthar's Super Store" />
          )}
        </Navbar.Brand>
        <Navbar.Toggle 
          aria-controls="basic-navbar-nav"
          onClick={toggleNavbar}
          className={isOpen ? 'navbar-toggler open' : 'navbar-toggler'}
        >
          <div className="hamburger-icon">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          {isAuthenticated ? (
          <Nav className="ms-auto" onClick={closeNavbar}>
            <Nav.Link as={Link} to="/">
              <FaHome style={{ marginRight: '5px' }} />
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/products">
              <FaBoxOpen style={{ marginRight: '5px' }} />
              Products
            </Nav.Link>
            <Nav.Link as={Link} to="/waitlist">
              <FaBoxOpen style={{ marginRight: '5px' }} />
              Waitlist
            </Nav.Link>
            <Nav.Link as={Link} to="/purchases">
              <FaShoppingCart style={{ marginRight: '5px' }} />
              Purchases
            </Nav.Link>
            <Nav.Link as={Link} to="/shipping">
              <FaShippingFast style={{ marginRight: '5px' }} />
              Shipping
            </Nav.Link>
            <Nav.Link as={Link} to="/settings">
              <FaCogs style={{ marginRight: '5px' }} />
              Settings
            </Nav.Link>
            <Nav.Link onClick={toggleTheme}>
              {isDarkTheme ? (
                <>
                  <FaSun style={{ marginRight: '5px' }} />Light Mode
                </>
              ) : (
                <>
                  <FaMoon style={{ marginRight: '5px' }} />Dark Mode
                </>
              )}
            </Nav.Link>
            <Nav.Link onClick={handleLogout}>
              Logout
            </Nav.Link>
          </Nav>
          ) : (
          <Nav className="ms-auto" onClick={closeNavbar}>
            <Nav.Link href={`${STORE_BACKEND_URL}/auth/google`}>
              Login with Google
            </Nav.Link>
          </Nav>
          )}
        </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;