import React, { createContext, useState, useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const { token } = useContext(AuthContext);
  const [stores, setStores] = useState([]); // State to hold available stores
  const [selectedStore, setSelectedStore] = useState(''); // State for the selected store
  const [storeLogo, setStoreLogo] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch stores from the backend
  const fetchStores = async () => {
    if (!token) return;
    
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_STORE_BACKEND_URL}/stores`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error fetching stores.');
      }

      const storesData = await response.json();
      setStores(storesData);
      
      if (storesData.length > 0) {
        const storedStoreId = localStorage.getItem('selectedStore');
        const initialStoreId = storedStoreId || storesData[0]._id;
        setSelectedStore(initialStoreId);

        const initialStore = storesData.find(store => store._id === initialStoreId);
        setStoreLogo(initialStore.store_logo || '');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchStores();
    }
  }, [token]);

  return (
    <StoreContext.Provider
      value={{
        stores,
        selectedStore,
        setSelectedStore,
        storeLogo,
        setStoreLogo,
        loading,
        error,
        fetchStores, // Expose fetchStores for manual calls if needed
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
