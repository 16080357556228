import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { StoreContext } from '../contexts/StoreContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import { FaStripeS, FaArchive, FaMap, FaWeightHanging } from 'react-icons/fa';
import Select from 'react-select'; // React-Select for Autocomplete
import countryLookup from '../utils/countryLookup'; // Import the country lookup
import { formatPrice }   from '../utils/helper';

const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

const MAX_ZONES = 5;
const MAX_WEIGHT_CLASSES = 6;

function Shipping() {
  const { token } = useContext(AuthContext);
  const [shippingRates, setShippingRates] = useState([]);
  const { stores, selectedStore, setSelectedStore, setStoreLogo, fetchStores } = useContext(StoreContext);
  const [zones, setZones] = useState(3);
  const [weights, setWeights] = useState(3);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pricePool, setPricePool] = useState([]);
  const [shippingMatrix, setShippingMatrix] = useState(
    Array.from({ length: 3 }, () => Array(3).fill(null))
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedWeight, setSelectedWeight] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formState, setFormState] = useState({
    display_name: editMode ? '' : 'Ground shipping',
    fixed_amount: {
      amount: '',
      currency: 'USD'
    },
    delivery_estimate: {
      maximum: { value: '' },
      minimum: { value: '' }
    },
    metadata: { tier: '', above_subtotal: '' },
    active: true,
    store_id: selectedStore
  });

  const [isZoneModalOpen, setIsZoneModalOpen] = useState(false);
  const [isWeightModalOpen, setIsWeightModalOpen] = useState(false);

  const [zoneSettings, setZoneSettings] = useState([
    { zone_number: 1, countries: [], label: 'Zone 1' },
    { zone_number: 2, countries: [], label: 'Zone 2' },
    { zone_number: 3, countries: [], label: 'Zone 3' },
  ]);
  
  const [weightSettings, setWeightSettings] = useState([
    { weight_class: 1, min_weight: 0, max_weight: 0.5, label: 'Up to 8oz' },
    { weight_class: 2, min_weight: 0.5, max_weight: 1, label: 'Up to 1lbs' },
    { weight_class: 3, min_weight: 1, max_weight: 3, label: '1 to 3lbs' },
  ]);

  const [selectedZoneIndex, setSelectedZoneIndex] = useState(null); 
  const [selectedCountries, setSelectedCountries] = useState(selectedZone?.countries || []);

  const countryOptions = Object.keys(countryLookup).map(code => ({
    value: code,
    label: `${countryLookup[code]} (${code})`
  }));

  useEffect(() => {
    if (token) {
      fetchStores();
      if (selectedStore) {
        fetchShippingSettings(selectedStore);
        fetchShippingRates(selectedStore);
      }
    }
  }, [token, selectedStore]);

  const handleStoreChange = (e) => {
    const newStoreId = e.target.value;
    setSelectedStore(newStoreId);
    localStorage.setItem('selectedStore', newStoreId); // Save selected store to localStorage

    const selected = stores.find(store => store._id === newStoreId);
    setStoreLogo(selected ? selected.store_logo : '');
    setEditMode(false); 
    setFormVisible(false); // hide the input form when store is changed
  };

  const fetchShippingSettings = async (storeId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${STORE_BACKEND_URL}/settings/shipping/${storeId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error fetching settings.');
      }

      const data = await response.json();
      console.log('Shipping settings data: ' + JSON.stringify(data));

      const defaultZones = [
        { zone_number: 1, countries: [], label: 'Zone 1' },
        { zone_number: 2, countries: [], label: 'Zone 2' },
        { zone_number: 3, countries: [], label: 'Zone 3' }
      ];
      const defaultWeights = [
        { weight_class: 1, min_weight: 0, max_weight: 0.5, label: 'Up to 8oz' },
        { weight_class: 2, min_weight: 0.5, max_weight: 1, label: 'Up to 1lbs' },
        { weight_class: 3, min_weight: 1, max_weight: 3, label: '1 to 3lbs' }
      ];

      setZoneSettings(data.zone_settings && data.zone_settings.length ? data.zone_settings : defaultZones);
      setWeightSettings(data.zone_settings && data.weight_settings.length ? data.weight_settings : defaultWeights);

      // Update the shippingMatrix with the data from shipping_rates
      const shippingRates = data.shipping_rates || [];
      // Calculate the maximum zone and weight_class from the fetched shippingRates
      const maxZone = Math.max(...shippingRates.map(rate => rate.zone), 3); // Default to at least 3 zones
      const maxWeightClass = Math.max(...shippingRates.map(rate => rate.weight_class), 3); // Default to at least 3 weight classes

      setZones(maxZone);  // Dynamically set zones
      setWeights(maxWeightClass);  // Dynamically set weights

      // Create the updated matrix based on maxZone and maxWeightClass
      const updatedMatrix = Array.from({ length: maxZone }, () => Array(maxWeightClass).fill(null));

      shippingRates.forEach(rate => {
        updatedMatrix[rate.zone - 1][rate.weight_class - 1] = {
          amount: rate.amount,
          currency: rate.currency,
          rate_id: rate.rate_id,
          label: rate.label
        };
      });

      console.log('updatedMatrix: ' + JSON.stringify(updatedMatrix));
      setShippingMatrix(updatedMatrix);  // Set the matrix state  
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveMatrix = async (updatedZones, updatedWeights) => {
    setLoading(true);
  
    // Prepare the shipping rates for storage in MongoDB
    const shippingRatesToSave = [];
  
    shippingMatrix.forEach((zone, zoneIndex) => {
      zone.forEach((rate, weightIndex) => {
        if (rate) {
          shippingRatesToSave.push({
            zone: zoneIndex + 1,
            weight_class: weightIndex + 1,
            amount: rate.amount,
            currency: rate.currency,
            rate_id: rate.rate_id,
            label: rate.label
          });
        }
      });
    });
  
    console.log('zoneSettings: ' + JSON.stringify(updatedZones, updatedWeights));

    const settingsToSave = {
      shipping_rates: shippingRatesToSave,  // Save the shipping matrix
      zone_settings: updatedZones || zoneSettings,  // Use passed updated zones or default to current zoneSettings
      weight_settings: updatedWeights || weightSettings
    };
  
    console.log ('settingsToSave: ' + JSON.stringify(settingsToSave));

    try {
      const response = await fetch(`${STORE_BACKEND_URL}/settings/shipping/${selectedStore}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(settingsToSave),
      });

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.error || `Error ${editMode ? 'updating' : 'creating'} shipping rate.`);
        return false;
      }
  
      toast.success('Shipping matrix updated successfully!');
      return true;
    } catch (error) {
      toast.error(error.message || 'An error occurred. Please try again.');
      return false; 
    } finally {
      setLoading(false);
    }
  };  

  const fetchShippingRates = async (storeId) => {
    setLoading(true);
    try {
      const response = await fetch(`${STORE_BACKEND_URL}/shipping/${storeId}/shipping-rates`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error('Error fetching shipping rates.');
      }
      const data = await response.json();
      setShippingRates(data);

      const formattedPricePool = data.map(rate => ({
        amount: rate.fixed_amount.amount,
        currency: rate.fixed_amount.currency,
        active: rate.active,
        id: rate._id,
        label: `${formatPrice(rate.fixed_amount.amount, rate.fixed_amount.currency)} - ${rate.display_name}`
      }));

      setPricePool(formattedPricePool);
    } catch (error) {
      toast.error(error.message || 'An error occurred while fetching shipping rates.');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // Split the name into parts (for example, "fixed_amount.amount" becomes ["fixed_amount", "amount"])
    const keys = name.split(".");
  
    // Create a copy of the formState
    let newState = { ...formState };
  
    // Traverse the formState object to the last nested field
    keys.reduce((acc, key, idx) => {
      if (idx === keys.length - 1) {
        // Update the value at the deepest level
        acc[key] = value;
      } else {
        // Ensure the intermediate keys are objects (to handle cases where they don't exist)
        acc[key] = acc[key] || {};
      }
      return acc[key];
    }, newState);
  
    // Set the new state
    setFormState(newState);
  };

  const handleSaveShippingRate = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Create a copy of formState to modify for sending to the backend
    const requestData = { ...formState };

    // Convert the amount to cents before sending to the backend, but do not modify the form state
    if (requestData.fixed_amount.amount && requestData.fixed_amount.amount > 0) {
      requestData.fixed_amount.amount = requestData.fixed_amount.amount * 100;
    }

    const url = editMode 
      ? `${STORE_BACKEND_URL}/shipping/shipping-rate/${formState._id}` 
      : `${STORE_BACKEND_URL}/shipping/shipping-rate`;
    const method = editMode ? 'PUT' : 'POST';

    try {
      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(requestData),
      });

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.error || `Error ${editMode ? 'updating' : 'creating'} shipping rate.`);
      }

      toast.success(responseData.message || `Shipping rate ${editMode ? 'updated' : 'created'} successfully!`);
      /*
      const newRate = {
        id: responseData._id,
        label: `${responseData.fixed_amount.amount} ${responseData.fixed_amount.currency} - ${responseData.display_name}`
      };
      setPricePool(prevPricePool => [...prevPricePool, newRate]);
      */

      setFormState({
        display_name: '',
        fixed_amount: { amount: '', currency: 'USD' },
        delivery_estimate: { maximum: { value: '' }, minimum: { value: '' } },
        metadata: { tier: '', above_subtotal: '' }
      });
      setEditMode(false);
      setFormVisible(false);
      fetchShippingRates(selectedStore);
    } catch (error) {
      toast.error(error.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddShippingRate = () => {
    setFormState({
      display_name: '',
      fixed_amount: { amount: '', currency: 'USD' },
      delivery_estimate: { maximum: { value: '' }, minimum: { value: '' } },
      metadata: { tier: '', above_subtotal: '' },
      store_id: selectedStore
    });
    setEditMode(false);
    setFormVisible(true);
  };

  const handleArchiveShippingRate = async (rateId) => {
    setLoading(true);
    setFormVisible(false);
    try {
      const response = await fetch(`${STORE_BACKEND_URL}/shipping/shipping-rate/${rateId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ active: false }), // Set the active status to false
      });

      if (!response.ok) {
        throw new Error('Error archiving shipping rate.');
      }

      toast.success('Shipping rate archived successfully!');
      fetchShippingRates(selectedStore); // Refresh shipping rates
    } catch (error) {
      toast.error(error.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleResumeShippingRate = async (rateId) => {
    setLoading(true);
    setFormVisible(false);
    try {
      const response = await fetch(`${STORE_BACKEND_URL}/shipping/shipping-rate/${rateId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ active: true }), // Set the active status to true
      });

      if (!response.ok) {
        throw new Error('Error resuming shipping rate.');
      }

      toast.success('Shipping rate resumed successfully!');
      fetchShippingRates(selectedStore); // Refresh shipping rates
    } catch (error) {
      toast.error(error.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Open the modal when a cell is clicked
  const openModal = (zoneIndex, weightIndex) => {
    setSelectedZone(zoneIndex);
    setSelectedWeight(weightIndex);
    setIsModalOpen(true);
  };

  const handleSelectRate = (rate) => {
    const updatedMatrix = [...shippingMatrix];
    updatedMatrix[selectedZone][selectedWeight] = {
      amount: rate.amount,
      currency: rate.currency,
      rate_id: rate.id,
      label: rate.label
    };
    setShippingMatrix(updatedMatrix);  // Update matrix state
  
    handleSaveMatrix();  // Save changes to MongoDB
    setIsModalOpen(false);  // Close modal
  };

  const openZoneModal = (zoneIndex) => {
    const zone = zoneSettings[zoneIndex];
    setSelectedZoneIndex(zoneIndex);  // Track the zone index
    setSelectedZone({
      ...zone,
      countries: Array.isArray(zone.countries) ? zone.countries : []  // Ensure countries is an array before joining
    });

    console.log('selectedZone: ', selectedZone);
    console.log('zone.countries: ', zone.countries);

    setIsZoneModalOpen(true);
  };

  const handleCountriesChange = (selectedOptions) => {
    const countryCodes = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedZone({ ...selectedZone, countries: countryCodes }); // Set country codes in the zone
    setSelectedCountries(countryCodes); // Update selected countries state
  };

  const handleZoneChange = (e) => {
    const { name, value } = e.target;
    setSelectedZone((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  
  const saveZoneSettings = () => {
    const updatedZones = [...zoneSettings];

    //console.log ('Countries: ' + JSON.stringify(selectedZone.countries));

    // Ensure countries is an array, splitting the string if needed
    const countriesArray = Array.isArray(selectedZone.countries)
      ? selectedZone.countries  // Already an array
      : selectedZone.countries.split(',').map((code) => code.trim());  // Convert from string to array

    updatedZones[selectedZoneIndex] = {  // Use selectedZoneIndex to update the correct zone
      ...selectedZone,
      countries: countriesArray // Save countries as an array
    };

    console.log ('Updated Zones: ', updatedZones);

    setZoneSettings(updatedZones);
    setIsZoneModalOpen(false);
    handleSaveMatrix(updatedZones, weightSettings);

    //handleSaveMatrix();  // Save changes to MongoDB
  };

  const openWeightModal = (weightIndex) => {
    setSelectedWeight(weightSettings[weightIndex]);
    setIsWeightModalOpen(true);
  };
  
  const handleWeightChange = (e) => {
    const { name, value } = e.target;
    setSelectedWeight((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  
  const saveWeightSettings = async () => {
    const updatedWeights = [...weightSettings];
    const index = updatedWeights.findIndex(
      (weight) => weight.weight_class === selectedWeight.weight_class
    );
    updatedWeights[index] = selectedWeight;
    setWeightSettings(updatedWeights);
  
    // Call handleSaveMatrix and check if it was successful
    const saveSuccessful = await handleSaveMatrix(zoneSettings, updatedWeights);
    if (saveSuccessful) {
      setIsWeightModalOpen(false);  // Close the modal only if the save was successful
    }
  };

  const addZone = () => {
    if (zones < MAX_ZONES) {
      setZones(zones + 1);

      // Add a new row (zone) with the current number of weight columns to the shippingMatrix
      const newZoneMatrix = Array.from({ length: weights }, () => null);
      setShippingMatrix((prevMatrix) => [...prevMatrix, newZoneMatrix]);

      // Add a new zone to zoneSettings
      const newZoneSettings = {
        zone_number: zones + 1,  // Increment zone number
        countries: [],  // Empty countries for new zone
        label: `Zone ${zones + 1}`,  // Default label for the new zone
      };
      setZoneSettings((prevSettings) => [...prevSettings, newZoneSettings]);  // Update zoneSettings
    }
  };

  const addWeightClass = () => {
    if (weights < MAX_WEIGHT_CLASSES) {
      setWeights(weights + 1);

      // Add a new column (weight class) to each zone in the shippingMatrix
      const updatedMatrix = shippingMatrix.map((zone) => [...zone, null]);
      setShippingMatrix(updatedMatrix);

      // Add a new weight class to weightSettings
      const newWeightClass = {
        weight_class: weights + 1,  // Increment weight class number
        min_weight: 0,  // Default min weight
        max_weight: 1,  // Default max weight
        label: `Weight Class ${weights + 1}`,  // Default label
      };
      setWeightSettings((prevSettings) => [...prevSettings, newWeightClass]);  // Update weightSettings
    }
  };

  return (
    <div>
      <ToastContainer />

      <div className="headline d-flex justify-content-between align-items-center">
        <h2 className="underline">Shipping</h2>
        <div className="form-group">
          <label></label>
          <select
            id="storeSelect"
            className="form-control"
            value={selectedStore}
            onChange={handleStoreChange} // Use the handleStoreChange function
          >
            {stores.map((store) => (
              <option key={store._id} value={store._id}>
                {store.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Make the table scrollable on small screens */}
      <div className="table-responsive" style={{ overflowX: 'auto' }}>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th></th>
              {weightSettings.map((weight, index) => (
                <th key={index} className="hoverable" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => openWeightModal(index)}>
                  {weight.label}<br />({weight.min_weight} lbs - {weight.max_weight} lbs)
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {zoneSettings.map((zone, zoneIndex) => (
              <tr key={zoneIndex}>
                <th className="hoverable"  style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => openZoneModal(zoneIndex)}>
                  {zone.label}<br />({Array.isArray(zone.countries) ? zone.countries.join(', ') : ''})
                </th>
                {Array.from({ length: weightSettings.length }, (_, weightIndex) => (
                  <td key={weightIndex} onClick={() => openModal(zoneIndex, weightIndex)} className="hoverable" style={{ cursor: 'pointer', textAlign: 'center' }}>
                    {shippingMatrix[zoneIndex] && shippingMatrix[zoneIndex][weightIndex]
                      ? shippingMatrix[zoneIndex][weightIndex].label
                      : 'Click to select price'}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between">
        <Button
          variant="outline-primary" 
          className="btn-sm d-flex align-items-center"
          onClick={addZone} 
          disabled={zones >= MAX_ZONES}
        >
          <FaMap style={{ marginRight: '5px' }} /> Add zone
        </Button>
        <Button
          variant="outline-primary" 
          className="btn-sm d-flex align-items-center"
          onClick={addWeightClass} 
          disabled={weights >= MAX_WEIGHT_CLASSES}
        >
          <FaWeightHanging style={{ marginRight: '5px' }} /> Add weight class
        </Button>
      </div>

      <div className="headline d-flex justify-content-between align-items-center" style={{ marginTop: '30px' }}>
        <h2 className="underline">Shipping rates</h2>
        <Button className="btn btn-sm btn-primary" onClick={handleAddShippingRate}>
          Add rate
        </Button>
      </div>

      {formVisible && (
        <form onSubmit={handleSaveShippingRate}>
          <div className="form-group">
            <label style={{ whiteSpace: 'nowrap' }}>Display Name
            {formState.synced_with_stripe && (
                <FaStripeS 
                  aria-label="Sync'd with Stripe" 
                  style={{ 
                    marginLeft: '10px', 
                    color: '#FFFFFF', 
                    backgroundColor: '#635BFF', 
                    fontSize: '20px',
                    display: 'inline-block',
                    lineHeight: 0,
                    padding: '5px',
                    borderRadius: '3px'
                  }} 
                />
              )}
            </label>
            <input
              type="text"
              name="display_name"
              className="form-control"
              placeholder='Ground shipping'
              value={formState.display_name}
              onChange={handleInputChange}
              required
              disabled={editMode}
            />
          </div>
          <div className="form-group">
            <label>Shipping Amount</label>
            <input
              type="number"
              name="fixed_amount.amount"
              className="form-control"
              placeholder='10.00'
              value={formState.fixed_amount?.amount || ''}
              onChange={handleInputChange}
              required
              disabled={editMode}
            />
          </div>
          <div className="form-group">
            <label>Currency</label>
            <select
              name="fixed_amount.currency"
              className="form-control"
              value={formState.fixed_amount?.currency || 'USD'}
              onChange={handleInputChange}
              required
              disabled={editMode}
            >
              <option value="USD">USD</option>
              {/* <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>*/}
            </select>
          </div>
          <div className="form-group">
            <label>Delivery Estimate Min (days)</label>
            <input
              type="number"
              name="delivery_estimate.minimum.value"
              className="form-control"
              value={formState.delivery_estimate && formState.delivery_estimate.minimum && formState.delivery_estimate.minimum.value || ''}
              onChange={handleInputChange}
              disabled={editMode}
            />
          </div>
          <div className="form-group">
            <label>Delivery Estimate Max (days)</label>
            <input
              type="number"
              name="delivery_estimate.maximum.value"
              className="form-control"
              value={formState.delivery_estimate && formState.delivery_estimate.maximum && formState.delivery_estimate.maximum.value || ''}
              onChange={handleInputChange}
              disabled={editMode}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            {loading ? 'Saving...' : editMode ? 'Update Shipping Rate' : 'Create Shipping Rate'}
          </button>
        </form>
      )}

      <div className="row">
        {shippingRates && shippingRates.map((rate) => (
          <div key={rate._id} className="col-md-6 mb-3">
            <div className="list-group-item d-flex justify-content-between align-items-center">
              <div>
                <strong>{rate.display_name} 
                  {rate.synced_with_stripe && (
                    <FaStripeS aria-label="Sync'd with Stripe" style={{ 
                        marginLeft: '10px', 
                        color: '#FFFFFF',
                        backgroundColor: '#635BFF',
                        fontSize: '20px',
                        display: 'inline-block',
                        lineHeight: 0,
                        padding: '5px',
                        borderRadius: '3px'
                      }} 
                    />
                  )}
                </strong>
                <div>Shipping Rate: {formatPrice(rate.fixed_amount.amount, rate.fixed_amount.currency.toUpperCase())}</div>
                <div>Delivery: {rate.delivery_estimate?.minimum?.value} - {rate.delivery_estimate?.maximum?.value} days</div>
              </div>
              <div style={{ whiteSpace: 'nowrap' }}>
                <span className={`badge ${rate.active ? 'badge-success' : 'badge-secondary'} mr-2`}>
                  {rate.active ? 'Active' : 'Archived'}
                </span>
                {rate.active ? (
                  <button className="btn btn-danger btn-sm ml-2" onClick={() => handleArchiveShippingRate(rate._id)}>
                    <FaArchive /> {/* Archive Icon */}
                  </button>
                ) : (
                  <button className="btn btn-secondary btn-sm ml-2" onClick={() => handleResumeShippingRate(rate._id)}>
                    <FaArchive /> {/* Resume Icon */}
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for selecting shipping price */}
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select Shipping Rate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-group">
            {pricePool
              .filter((rate) => rate.active)  // Filter to only show active rates
              .map((rate) => (
              <li
                key={rate.id}
                className="list-group-item list-group-item-action"
                style={{ cursor: 'pointer' }}
                onClick={() => handleSelectRate(rate)}
              >
                {rate.label}
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isZoneModalOpen} onHide={() => setIsZoneModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Zone</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row align-items-center">
            <label className="col-sm-3 col-form-label">Zone Label</label>
            <div className="col-sm-9">
              <input
                type="text"
                style={{ margin: '0px' }}
                name="label"
                value={selectedZone?.label || ''}
                onChange={handleZoneChange}
                className="form-control"
              />
            </div>
          </div>
          
          <div className="form-group row align-items-center">
            <label className="col-sm-3 col-form-label">Countries</label>
            <div className="col-sm-9">
              <Select
                isMulti
                options={countryOptions} // Options for autocomplete from countryLookup
                value={Array.isArray(selectedZone?.countries)
                  ? selectedZone.countries.map(code => ({
                      value: code,
                      label: countryLookup[code] || code
                    }))
                  : []} // Ensure countries is an array, otherwise fallback to an empty array
                onChange={handleCountriesChange} // Update selected countries
                placeholder="Select countries"
                className="multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsZoneModalOpen(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveZoneSettings}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isWeightModalOpen} onHide={() => setIsWeightModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Weight Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Weight Class Label</label>
            <input
              type="text"
              name="label"
              value={selectedWeight?.label || ''}
              onChange={handleWeightChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Min Weight (lbs)</label>
            <input
              type="number"
              name="min_weight"
              value={selectedWeight?.min_weight || ''}
              onChange={handleWeightChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Max Weight (lbs)</label>
            <input
              type="number"
              name="max_weight"
              value={selectedWeight?.max_weight || ''}
              onChange={handleWeightChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Height (in)</label>
            <input
              type="number"
              name="height"
              value={selectedWeight?.height || ''}
              onChange={handleWeightChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Width (in):</label>
            <input
              type="number"
              name="width"
              value={selectedWeight?.width || ''}
              onChange={handleWeightChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Length (in):</label>
            <input
              type="number"
              name="length"
              value={selectedWeight?.length || ''}
              onChange={handleWeightChange}
              className="form-control"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsWeightModalOpen(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveWeightSettings}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      
    </div>
  );
}

export default Shipping;
