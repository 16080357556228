import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './Products.css';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from '../contexts/AuthContext';
import { StoreContext } from '../contexts/StoreContext';
import { convertType }   from '../utils/helper';

const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

// Format price by accepting a decimal or a whole number and always format it as a dollar value with 2 decimal places
const formatPrice = (amount) => {
  if (amount || amount === 0) {
    return Number(amount).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });
  } else {
    return "";
  }
};

function Products() {
  const { token, isAuthenticated } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const { stores, selectedStore, setSelectedStore, setStoreLogo, fetchStores } = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const [totalPages, setTotalPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(1);
  const [selectedType, setSelectedType] = useState('sale'); // Product type filter
  const [typeFilterTitle, setTypeFilterTitle] = useState('For Sale');

  const itemsPerPage = 10; // Limit items per page

  // Fetch stores only once when the component is mounted or when token changes
  useEffect(() => {
    if (isAuthenticated && token) {
      fetchStores(); // Fetch stores when authenticated and token is available
    }
  }, [isAuthenticated, token]);

  // Fetch products when the selected store, current page, or product type changes
  useEffect(() => {
    if (selectedStore) {
      fetchProducts(selectedStore, currentPage, selectedType);
    }
  }, [selectedStore, currentPage, selectedType]); // Separate dependency list for fetching products

  const fetchProducts = async (storeId, page, type) => {
    setLoading(true);
    setError(null);
  
    console.log('Fetching products for store: ' + storeId);
  
    try {
      const response = await fetch(`${STORE_BACKEND_URL}/products/store/${storeId}?page=${page}&limit=${itemsPerPage}&type=${type || ''}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        // Extract error message from the response body
        const errorData = await response.json();
        const errorMessage = errorData.error || 'Error fetching products.';
        throw new Error(errorMessage);
      }
  
      const data = await response.json();
      setProducts(data.products);
      setTotalPages(data.total_pages);
      setTotalProducts(data.total_products);
    } catch (error) {
      // Show actual error message from the backend in the toast and setError
      //toast.error(error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleStoreChange = (e) => {
    const newStoreId = e.target.value;
    setSelectedStore(newStoreId);
    localStorage.setItem('selectedStore', newStoreId); // Save selected store to localStorage

    const selected = stores.find(store => store._id === newStoreId);
    setStoreLogo(selected ? selected.store_logo : '');
  };

  const handleTypeChange = (filter, title) => {
    setSelectedType(filter);
    setTypeFilterTitle(title);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDeleteProduct = async (productId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${STORE_BACKEND_URL}/products/${productId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error deleting product.');
      }

      toast.success('Product deleted successfully!');
      fetchProducts(selectedStore); // Refresh products after deletion
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="theme-container">
      <ToastContainer />
      <div className="headline d-flex justify-content-between align-items-center">
        <h2 className="underline">Products</h2>
        {/* Store Filter */}
        <div className="form-group">
          <label></label>
          <select
            id="storeSelect"
            className="form-control"
            value={selectedStore}
            onChange={handleStoreChange} // Use the handleStoreChange function
          >
            {stores.map((store) => (
              <option key={store._id} value={store._id}>
                {store.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <Link to="/add-product">
          <h6>Add Product</h6>
        </Link>
      </div>
      <div className="filter-buttons">
        <DropdownButton id="dropdown-basic-button" title={`${typeFilterTitle}`} align="end">
          <Dropdown.Item onClick={() => handleTypeChange('', 'All')}>All</Dropdown.Item>
          <Dropdown.Item onClick={() => handleTypeChange('sale', 'For Sale')}>For Sale</Dropdown.Item>
          <Dropdown.Item onClick={() => handleTypeChange('showcase', 'Showcase')}>Showcase</Dropdown.Item>
          <Dropdown.Item onClick={() => handleTypeChange('affiliate', 'Affiliate')}>Affiliate</Dropdown.Item>
        </DropdownButton>
      </div>

      {loading && <div>Loading...</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <h4>Total records: {totalProducts}</h4>
      {!loading && !error && products.length > 0 && (
        <div className="row">
          {products.map((product) => (
            <div className="col-md-6" key={product._id}>
              <div className="card mb-12" style={{ marginBottom: '10px' }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4" style={{ textAlign: 'left' }}>
                      {product.images && product.images.length > 0 && (
                        <img 
                          src={`${product.images[0].url}`} 
                          alt={product.name} 
                          style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }} 
                        />
                      )}
                      <p>
                      <Link to={`/edit-product/${product._id}`}>Edit</Link>
                      </p>
                    </div>
                    <div className="col-md-8" style={{ textAlign: 'left' }}>
                      <p className="product-name">{product.name}</p>
                      <p className="product-description">{product.description}</p>
                      <p className="product-description">{product.weight} lbs</p>
                      <p>
                        {formatPrice(product.price/100)}  <br />
                        {convertType(product.type)} <br />
                        Inventory: {product.inventory}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button className="btn btn-primary" disabled={currentPage === 1} onClick={goToPreviousPage}>
          Previous
        </button>
        <span className="page-indicator">Page {currentPage} of {totalPages}</span>
        <button className="btn btn-primary" disabled={currentPage === totalPages} onClick={goToNextPage}>
          Next
        </button>
      </div>
    </div>
  );
}

export default Products;
