import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import Home from './pages/Home';
import AddOrEditPurchase from './pages/AddOrEditPurchase';
import AddOrEditProduct from './pages/AddOrEditProduct';
import Purchases from './pages/Purchases';
import Products from './pages/Products';
import Waitlist from './pages/Waitlist';
import ProductWaitlist from './pages/ProductWaitlist';
import Shipping from './pages/Shipping';
import { AuthContext } from './contexts/AuthContext';
import Settings from './pages/Settings';

function App() {
  const { isAuthenticated, validateToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(true); // Loading state to prevent rendering before auth is resolved
  const navigate = useNavigate();
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    // On initial load, get the theme from localStorage if available, otherwise default to light theme
    const storedTheme = localStorage.getItem('theme');
    return storedTheme === 'dark' ? true : false;
  });

  // Effect to validate the token and resolve authentication status
  useEffect(() => {
    const validate = async () => {
      const isValid = await validateToken();
      if (!isValid) {
        console.log('Invalid Token!');
        navigate('/'); // Redirect to home if the token is invalid
      }
      setLoading(false); // Set loading to false after token is validated
    };

    validate();
  }, [isAuthenticated, navigate, validateToken]);

  // Toggle theme and store the preference in localStorage
  const toggleTheme = () => {
    setIsDarkTheme(prevTheme => {
      const newTheme = !prevTheme;
      localStorage.setItem('theme', newTheme ? 'dark' : 'light'); // Store the theme in localStorage
      return newTheme;
    });
  };

  // Apply the selected theme to the document body
  useEffect(() => {
    if (isDarkTheme) {
      document.body.setAttribute('data-bs-theme', 'dark');
    } else {
      document.body.setAttribute('data-bs-theme', 'light');
    }
  }, [isDarkTheme]);

  if (loading) {
    return <div>Loading...</div>; // Render loading indicator until authentication status is resolved
  }

  return (
    <div className="theme-container">
      <NavigationBar toggleTheme={toggleTheme} isDarkTheme={isDarkTheme}/>
      <div className="container admin-page-wrapper">
        <Routes>
          <Route path="/" element={<Home />} />
          {isAuthenticated ? (
            <>
              <Route path="/purchases" element={<Purchases />} />
              <Route path="/add-purchase/:store_id" element={<AddOrEditPurchase isEdit={false} />} />
              <Route path="/edit-purchase/:id" element={<AddOrEditPurchase isEdit={true} />} />
              <Route path="/products" element={<Products />} />
              <Route path="/waitlist" element={<Waitlist />} />
              <Route path="/waitlist/:product_id" element={<ProductWaitlist />} />
              <Route path="/add-product" element={<AddOrEditProduct isEdit={false} />} />
              <Route path="/edit-product/:id" element={<AddOrEditProduct isEdit={true} />} />
              <Route path="/shipping" element={<Shipping />} />
              <Route path="/settings" element={<Settings />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/" replace />} /> // Redirect to home if not authenticated
          )}
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
