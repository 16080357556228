export function formatPrice(cents, currency) {
  if (cents !== undefined && currency) {
    // Convert price to a float (decimal)
    const parsedPrice = parseFloat(cents) / 100;
    
    // If parsedPrice is NaN, return a fallback value
    if (isNaN(parsedPrice)) return 'Invalid price';
  
    const currencyString = currency.toUpperCase();
    
    switch (currencyString) {
      case 'EUR':
        return '€' + parsedPrice.toFixed(2).replace('.', ','); // Use comma for EUR
      case 'GBP':
        return '£' + parsedPrice.toFixed(2); // British pound
      case 'USD':
        return '$' + parsedPrice.toFixed(2); // US Dollar
      case 'CAD':
        return '$' + parsedPrice.toFixed(2); // Canadian Dollar
      default:
        return '$' + parsedPrice.toFixed(2); // Default to USD or other currencies
    }
  }
  return;
}

export function convertType(type) {
  if (type !== undefined && type) {
    
    switch (type) {
      case 'sale':
        return 'For Sale';
      case 'showcase':
        return 'Showcase'; 
      case 'affiliate':
        return 'Affiliate'
      default:
        return ''
    }
  }
  return;
}
