import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StoreContext } from '../contexts/StoreContext';

const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;
const STORE_FRONTEND_URL = process.env.REACT_APP_STORE_FRONTEND_URL;

function Settings() {
  const { token } = useContext(AuthContext);
  const [otherSetting, setOtherSetting] = useState(''); // Example of another setting
  const { stores, selectedStore, setSelectedStore, setStoreLogo, fetchStores } = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [storeForm, setStoreForm] = useState({ name: '', description: '', handle: '', store_logo: null });
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false); // State to handle whether we are adding or editing
  const [showStoreForm, setShowStoreForm] = useState(false); // To control form visibility

  useEffect(() => {
    if (token) {
      fetchSettings();
      fetchStores(); // Fetch user's stores
    }
  }, [token]);

  const fetchSettings = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${STORE_BACKEND_URL}/settings`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error fetching settings.');
      }

      const data = await response.json();
      setOtherSetting(data.other_setting || '');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleStoreFormChange = (e) => {
    const { name, value } = e.target;
    setStoreForm({ ...storeForm, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setStoreForm({ ...storeForm, store_logo: file });
  };

  const handleOtherSettingChange = (e) => {
    setOtherSetting(e.target.value);
  };

  const handleSaveSettings = async (e) => {
    e.preventDefault();
    setLoading(true);

    const settingsToSave = {
      other_setting: otherSetting 
    };

    try {
      const response = await fetch(`${STORE_BACKEND_URL}/settings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(settingsToSave),
      });

      if (!response.ok) {
        throw new Error('Error saving settings.');
      }

      toast.success('Settings updated successfully!');
    } catch (error) {
      toast.error(error.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const uploadLogo = async (storeId) => {
    const formData = new FormData();
    formData.append('store_logo', storeForm.store_logo);

    try {
      const response = await fetch(`${STORE_BACKEND_URL}/upload/${storeId}/logo`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Error uploading store logo.');
      }

      return data;
    } catch (error) {
      toast.error(error.message || 'An error occurred during logo upload.');
    }
  };

  const handleAddOrEditStore = async (e) => {
    e.preventDefault();
    let logoUrl = storeForm.store_logo;

    // If editing and a new file (store logo) is selected, upload the file first
    if (editMode && storeForm.store_logo && storeForm.store_logo instanceof File) {
      const uploadedLogo = await uploadLogo(storeForm._id);
      console.log('Upload response: ' + JSON.stringify(uploadedLogo));
      if (uploadedLogo) {
        logoUrl = uploadedLogo.logo_url; // Set the path returned from the upload route
      } else {
        toast.error('Unable to upload store logo. Please try again later.');
        return;
      }
    }

    // Now, send the store data including the logo path (if any)
    const storeData = {
      name: storeForm.name,
      description: storeForm.description,
      handle: storeForm.handle,
      store_logo: logoUrl, // Include the logo path if uploaded
    };

    try {
      const url = editMode ? `${STORE_BACKEND_URL}/stores/${storeForm._id}` : `${STORE_BACKEND_URL}/stores`;
      const method = editMode ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(storeData), // Send the store data
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || `Error ${editMode ? 'updating' : 'adding'} store.`);
      }

      toast.success(data.message || `Store ${editMode ? 'updated' : 'added'} successfully!`);

      setStoreForm({ name: '', description: '', handle: '', store_logo: null }); // Reset form
      setEditMode(false); // Exit edit mode
      setShowStoreForm(false); // Hide the form after submission
      fetchStores(); // Refresh store list
    } catch (error) {
      toast.error(error.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditStore = (store) => {
    setStoreForm(store); // Fill form with store details for editing
    setEditMode(true); // Enter edit mode
    setShowStoreForm(true); // Show the form when editing
  };

  const handleAddStore = () => {
    setStoreForm({ name: '', description: '', handle: '', store_logo: null });
    setEditMode(false); // Set to add mode
    setShowStoreForm(true); // Show the form when adding
  };

  const handleCloseStore = async (storeId) => {
    setLoading(true);
    try {
      const response = await fetch(`${STORE_BACKEND_URL}/stores/${storeId}/close`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error closing store.');
      }

      toast.success('Store closed successfully!');
      fetchStores();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResumeStore = async (storeId) => {
    setLoading(true);
    try {
      const response = await fetch(`${STORE_BACKEND_URL}/stores/${storeId}/resume`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Error resuming store.');
      }
  
      toast.success('Store resumed successfully!');
      fetchStores(); // Refresh the store list after unarchiving
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <ToastContainer />
      <div className="headline d-flex justify-content-between align-items-center">
        <h2 className="underline">Settings</h2>
        <div className="form-group">

        </div>
      </div>
      <form onSubmit={handleSaveSettings}>
        <div className="form-group">
          <label>Example Setting:</label>
          <input type="text" name="otherSetting" className="form-control" value={otherSetting} onChange={handleOtherSettingChange} />
        </div>

        <button type="submit" className="btn btn-primary">Save Settings</button>
      </form>

      <div className="headline d-flex justify-content-between align-items-center">
        <h2 className="underline">Manage Stores</h2>
        <button className="btn btn-primary" onClick={handleAddStore}>+</button>
      </div>

      {/* Conditionally render the form based on whether adding or editing */}
      {showStoreForm && (
        <form onSubmit={handleAddOrEditStore}>
          {editMode && (
          <div className="form-group">
            <label>Store ID:</label>
            <input
              type="text"
              name="storeForm._id"
              className="form-control"
              value={storeForm._id}
              onChange={handleStoreFormChange}
              required
              disabled
            />
          </div>
          )}
          <div className="form-group">
            <label>Store Name:</label>
            <input
              type="text"
              name="name"
              className="form-control"
              value={storeForm.name}
              onChange={handleStoreFormChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Store Description:</label>
            <input
              type="text"
              name="description"
              className="form-control"
              value={storeForm.description}
              onChange={handleStoreFormChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Handle (must be at least 6 alphabetic characters):</label>
            <input
              type="text"
              name="handle"
              className="form-control"
              value={storeForm.handle}
              onChange={handleStoreFormChange}
              required
            />
          </div>

          {/* Show logo upload only when editing */}
          {editMode && (
            <div className="form-group">
              <label>Store Logo:</label>
              <input
                type="file"
                name="store_logo"
                className="form-control"
                onChange={handleFileChange}
              />
            </div>
          )}
          <button type="submit" className="btn btn-primary">{editMode ? 'Update Store' : 'Add Store'}</button>
        </form>
      )}

      <ul className="list-group">
        {stores.map((store) => (
          <li key={store._id} className="list-group-item d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              {/* Store Logo or Placeholder */}
              {store.store_logo ? (
                <img 
                  src={`${store.store_logo}`} // Prepend the frontend URL for actual logo
                  alt={store.name} 
                  style={{ maxHeight: '50px', maxWidth: '50px', objectFit: 'cover', marginRight: '10px' }} 
                />
              ) : (
                <img 
                  src={`${STORE_FRONTEND_URL}/placeholder.png`} // Replace with your placeholder image path
                  alt="Placeholder Logo" 
                  style={{ maxHeight: '50px', maxWidth: '50px', objectFit: 'cover', marginRight: '10px' }} 
                />
              )}
              {/* Store Name and Description */}
              <div>
                <strong>{store.name}</strong> - {store.description}
              </div>
            </div>
            <div>
              <button className="btn btn-secondary btn-sm" onClick={() => handleEditStore(store)}>Edit</button>
              {!store.is_archived ? (
                <button className="btn btn-warning btn-sm ml-2" onClick={() => handleCloseStore(store._id)}>Close Store</button>
              ) : (
                <button className="btn btn-success btn-sm ml-2" onClick={() => handleResumeStore(store._id)}>Resume Store</button>
              )}
            </div>
          </li>
        ))}
      </ul>


    </div>
  );
}

export default Settings;
