import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

function AddOrEditProduct({ isEdit }) {
  const { id } = useParams();
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    name: '',
    description: '',
    email_template: '',
    price: 0,
    type: '',
    affiliateLink: '',
    images: [], // Set images as an array to hold multiple images
    inventory: 0,
    priority: 0,
    type: 'product',
  });
  const [imageFiles, setImageFiles] = useState([]); // State to hold selected image files
  const [imagePreviews, setImagePreviews] = useState([]); // Previews for selected images
  const [removedImages, setRemovedImages] = useState([]); // State to hold images marked for soft delete
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [storeName, setStoreName] = useState('');

  const selectedStoreId = localStorage.getItem('selectedStore');

  useEffect(() => {
    if (token && selectedStoreId) {  
      // Fetch the store name based on selectedStoreId from localStorage
      fetch(`${STORE_BACKEND_URL}/stores/${selectedStoreId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Error fetching store name');
          }
          return response.json();
        })
        .then(data => setStoreName(data.name))
        .catch(error => console.error('Error fetching store name:', error));

      // If editing a product, fetch the product details
      if (isEdit && id) {
        fetchProduct(id);
      }
    }
  }, [isEdit, id, token, selectedStoreId]);

  const fetchProduct = async (productId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${STORE_BACKEND_URL}/products/${productId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching product.');
      }
      const productData = await response.json();

      // Map snake_case fields to camelCase for usage in the state
      setProduct({
        ...productData,
        price: (productData.price / 100).toFixed(2), 
        affiliateLink: productData.affiliate_link,
        created_at: productData.created_at, 
        updated_at: productData.updated_at,
        images: productData.images || [], // Default to an empty array
      });

      setImagePreviews(productData.images || []); // Display existing images in edit mode
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleImageSelection = (e) => {
    const selectedFiles = e.target.files;
    const currentImages = product.images || []; // Ensure product.images is always defined

    if (selectedFiles.length + currentImages.length > 10) {
      toast.error("You can upload a maximum of 10 images.");
      return;
    }

    setImageFiles(selectedFiles); // Store the selected files

    // Create image previews
    const previews = Array.from(selectedFiles).map((file) => URL.createObjectURL(file));
    setImagePreviews([...currentImages, ...previews]); // Append new previews to existing images
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'price') {
      // Allow raw input value and don't manipulate it right away
      if (value === '' || /^[0-9]*\.?[0-9]{0,2}$/.test(value)) {
        setProduct({ ...product, [name]: value });
      }
    } else {
      setProduct({ ...product, [name]: value });
    }
  };

  const handleUploadImages = async () => {
    const formData = new FormData();
    Array.from(imageFiles).forEach(file => {
      formData.append('images', file); // Append each image file to the formData
    });

    try {
      const response = await fetch(`${STORE_BACKEND_URL}/upload/${selectedStoreId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData
      });

      const data = await response.json();
      console.log('Uploaded image URLs:', data.image_urls); // Log the image URLs

      if (!response.ok || !data.image_urls) {
        throw new Error('Error uploading images.');
      }

      return data.image_urls; // Return the uploaded image URLs
    } catch (error) {
      toast.error(error.message);
      throw error; // Throw the error to handle it in `handleSaveProduct`
    }
  };

  const handleRemoveImage = (image, index) => {
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
    const updatedProductImages = product.images.filter((_, i) => i !== index);

    setImagePreviews(updatedPreviews); // Update the image previews
    setProduct({ ...product, images: updatedProductImages }); // Update the product state

    // Mark the image for soft deletion
    setRemovedImages((prev) => [...prev, image]);
  };

  const moveImageUp = (index) => {
    if (index === 0) return; // If it's already the first image, do nothing
    const newPreviews = [...imagePreviews];
    const newProductImages = [...product.images];

    // Swap with the previous image
    [newPreviews[index - 1], newPreviews[index]] = [newPreviews[index], newPreviews[index - 1]];
    [newProductImages[index - 1], newProductImages[index]] = [newProductImages[index], newProductImages[index - 1]];

    setImagePreviews(newPreviews);
    setProduct({ ...product, images: newProductImages });
  };

  const moveImageDown = (index) => {
    if (index === imagePreviews.length - 1) return; // If it's already the last image, do nothing
    const newPreviews = [...imagePreviews];
    const newProductImages = [...product.images];

    // Swap with the next image
    [newPreviews[index + 1], newPreviews[index]] = [newPreviews[index], newPreviews[index + 1]];
    [newProductImages[index + 1], newProductImages[index]] = [newProductImages[index], newProductImages[index + 1]];

    setImagePreviews(newPreviews);
    setProduct({ ...product, images: newProductImages });
  };

  const handleSaveProduct = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      let newImageUrls = [];

      // Upload new images before saving the product
      if (imageFiles.length > 0) {
        newImageUrls = await handleUploadImages(); // Get the newly uploaded image URLs
      }

      // Ensure product.images is defined and is an array
      const currentImages = product.images || [];

      // Combine new image URLs with the existing images (ensure correct structure)
      const updatedImages = [
        ...currentImages.map(img => ({ url: img.url || img, deleted_at: img.deleted_at || null })),
        ...newImageUrls.map(url => ({ url, deleted_at: null })) // Newly added images should have no deleted_at
      ];

      console.log('Updated Product Images:', JSON.stringify(updatedImages));

      const method = isEdit ? 'PUT' : 'POST';
      const endpoint = isEdit ? `${STORE_BACKEND_URL}/products/${id}` : `${STORE_BACKEND_URL}/products`;
      const response = await fetch(endpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...product,
          price: product.price * 100, // convert to cents before sending the value to backend
          affiliate_link: product.affiliateLink,
          inventory: Number(product.inventory), // Ensure inventory is sent as a number
          images: updatedImages, // Send the updated images array with the correct structure
          removed_images: removedImages, // Send images marked for soft delete
          store_id: selectedStoreId // Associate the product with the store from localStorage
        })
      });

      const responseData = await response.json(); // Parse the response data

      if (!response.ok) {
        const errorMessage = responseData.error || 'Error saving product'; // Use the error message from backend or fallback to a generic one
        throw new Error(errorMessage);
      }
      
      if (!isEdit) {
        if (responseData.product_id) {
          navigate(`/edit-product/${responseData.product_id}`); // Navigate to the product page after adding a new product
        } else {
          navigate(`/products`);
        }
      }

      toast.success(responseData.message || 'Product saved successfully!'); // Show success message from backend or fallback
    } catch (error) {
      //setError(error.message);
      toast.error(error.message); // Show error message in toast
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <ToastContainer />
      <h2>{isEdit ? 'Edit Product' : 'Add Product'}</h2>
      {loading && <div>Loading...</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {!isEdit && (
        <p className="text-muted">
          You can add images after creating the product by editing it.
        </p>
      )}
      <div className="product-form-container" style={{ display: 'flex' }}>
        {/* Form on the left */}
        <form onSubmit={handleSaveProduct} style={{ flex: 2 }}>
          <div className="form-group">
            <label>Store Name</label>
            <input
              type="text"
              name="storeName"
              className="form-control"
              value={storeName}
              disabled
            />
          </div>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              name="name"
              className="form-control"
              value={product.name || ''}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <textarea
              name="description"
              className="form-control"
              value={product.description || ''}
              onChange={handleInputChange}
              required
              rows="6"
            />
          </div>
          <div className="form-group">
            <label>Price</label>
            <input
              type="text"
              name="price"
              className="form-control"
              value={product.price || ''} // Convert cents to dollars
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Currency</label>
            <select
              name="currency"
              className="form-control"
              value={product.currency || ''}
              onChange={handleInputChange}
            >
              <option value="">Select Currency</option>
              <option value="USD">USD - US Dollar</option>
              <option value="CAD">CAD - Canadian Dollar</option>
              <option value="AUD">AUD - Australian Dollar</option>
              <option value="GBP">GBP - British Pound</option>
            </select>
          </div>
          <div className="form-group">
            <label>Type</label>
            <select
              name="type"
              className="form-control"
              value={product.type || ''}
              onChange={(e) => {
                const { value } = e.target;
                setProduct((prevProduct) => ({
                  ...prevProduct,
                  type: value,
                  affiliateLink: value !== 'affiliate' ? '' : prevProduct.affiliateLink, // Clear the link if type is not affiliate
                }));
              }}
              required
            >
            <option value="">Select Type</option>
            <option value="sale">For Sale</option>
            <option value="showcase">Showcase</option>
            <option value="service">Service</option>
            <option value="affiliate">Affiliate</option>
          </select>
          </div>
          {(!isEdit || product.type === 'affiliate') && (
            <div className="form-group">
              <label>Affiliate Link</label>
              <input
                type="text"
                name="affiliateLink"
                className="form-control"
                value={product.affiliateLink || ''} 
                onChange={handleInputChange}
                disabled={product.type !== 'affiliate'} // Disable if the type is not Affiliate
                placeholder={product.type === 'affiliate' ? 'Enter Affiliate Link' : 'Affiliate type required to enable'}
                required={product.type === 'affiliate'} // Make required when Affiliate is selected
              />
            </div>
          )}
          <div className="form-group">
            <label>Weight (lbs)</label>
            <input
              type="number"
              name="weight"
              className="form-control"
              value={product.weight || 0}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Inventory</label>
            <input
              type="number"
              name="inventory"
              className="form-control"
              value={product.inventory || 0}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Priority</label>
            <input
              type="number"
              name="priority"
              className="form-control"
              value={product.priority || 0}
              onChange={handleInputChange}
            />
          </div>
          {(isEdit) && (
          <div className="form-group">
            <label>Images</label>
            <input
              type="file"
              name="images"
              className="form-control"
              multiple
              onChange={handleImageSelection}
            />
          </div>
          )}
          <div className="form-group">
            <label>HS Code</label>
            <input
              type="text"
              name="hs_code"
              className="form-control"
              value={product.hs_code || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Email Template</label>
            <textarea
              name="email_template"
              className="form-control"
              value={product.email_template || ''}
              onChange={handleInputChange}
              required
              rows="6"
            />
          </div>
          <div className="form-group">
            <label>Created Date</label>
            <input
              type="text"
              className="form-control"
              value={product.created_at ? new Date(product.created_at).toLocaleString() : 'N/A'}
              readOnly
              disabled
            />
          </div>
          <div className="form-group">
            <label>Updated Date</label>
            <input
              type="text"
              className="form-control"
              value={product.updated_at ? new Date(product.updated_at).toLocaleString() : 'N/A'}
              readOnly
              disabled
            />
          </div>
          <div className="text-end">
            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  &nbsp;{isEdit ? 'Updating...' : 'Adding...'}
                </>
              ) : (
                isEdit ? 'Update Product' : 'Add Product'
              )}
            </button>
          </div>
        </form>

        {/* Image upload and previews available only in edit mode */}
        {isEdit && (
          <div className="image-preview-section" style={{ flex: 1 }}>
            <h5>Image Previews</h5>
            <small className="form-text text-muted">You can upload a maximum of 10 images.</small>
            {imagePreviews && imagePreviews.length > 0 ? (
              <div className="image-preview-container">
                {imagePreviews.map((image, idx) => {
                  const imageUrl = typeof image === 'string'
                  ? image.startsWith('blob') 
                    ? image 
                    : `${image}`
                  : image.url ? `${image.url}` : '';
              
                  return (
                    <div key={idx} style={{ position: 'relative', marginBottom: '10px' }}>
                      <img
                        src={imageUrl}
                        alt={`Preview ${idx + 1}`}
                        style={{ width: '100%' }}
                      />
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                        onClick={() => handleRemoveImage(image, idx)}
                      >
                        Remove
                      </button>
                      <div style={{ position: 'absolute', bottom: '10px', right: '10px', display: 'flex' }}>
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm"
                          onClick={() => moveImageUp(idx)}
                          disabled={idx === 0}
                        >
                          Up
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm"
                          onClick={() => moveImageDown(idx)}
                          disabled={idx === imagePreviews.length - 1}
                          style={{ marginLeft: '5px' }}
                        >
                          Down
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p>No images selected.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AddOrEditProduct;
